import { useMemo } from 'react'
import { ApolloClient, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { cache } from './cache'

let apolloClient
let accessToken = null

const uri = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT

function createApolloClient() {
  const uploadLink = createUploadLink({ uri })

  const errorLink = onError(({ networkError }) => {
    if (!networkError) return
    if (typeof window === 'undefined') return
    if (networkError.bodyText !== 'Credentials are invalid') return
    window.location.assign('/logout')
  })

  const authLink = setContext((_, { headers }) => {
    if (accessToken === null || accessToken === undefined) {
      return { headers }
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`
      }
    }
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: from([authLink, errorLink, uploadLink]),
    cache,
    connectToDevTools: false // typeof window !== 'undefined'
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export function useApollo(initialState, { token }) {
  if (token !== null) {
    accessToken = token
  }
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
