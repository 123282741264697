import { createContext, useContext, useReducer } from 'react'

const fetchOptions = {
  method: 'POST',
  mode: 'same-origin',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  }
}

const ProfileContext = createContext({})

function profileReducer(state, action) {
  switch (action.type) {
    case 'update': {
      return { profile: action.payload.profile }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}.`)
    }
  }
}

function ProfileProvider({ initialState, children }) {
  const [state, dispatch] = useReducer(profileReducer, initialState)
  const value = { state, dispatch }
  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

function useProfile() {
  const context = useContext(ProfileContext)
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider.')
  }
  return context
}

// async function fetchProfile({ dispatch }) {
//   const response = await fetch('/api/me', fetchOptions)
//   dispatch({ type: 'update', payload: response.profile })
// }

async function updatePermissions({ dispatch }, permissions) {
  const body = JSON.stringify(permissions)
  const response = await fetch('/api/permissions', { ...fetchOptions, body })
  const json = await response.json()
  const profile = json.profile
  dispatch({ type: 'update', payload: { profile } })
}

export { ProfileProvider, useProfile, updatePermissions }
