import { InMemoryCache, makeVar } from '@apollo/client'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        programGoals: {
          merge(_, incoming) {
            return incoming
          }
        }
        // ingredientGroups: {
        //   keyArgs: false,
        //   merge(existing = { edges: [], pageInfo: {} }, incoming) {
        //     // if (incoming.pageInfo.hasPreviousPage) {
        //     //   return {
        //     //     ...existing,
        //     //     edges: [...existing.edges, ...incoming.edges],
        //     //     pageInfo: incoming.pageInfo
        //     //   }
        //     // }
        //     return incoming
        //   }
        // }
      }
    },
    Goal: {
      fields: {
        nutrients: {
          merge(_, incoming) {
            // Equivalent to what happens if there is no custom merge function.
            return incoming
          }
        }
      }
    }
  }
})

export const isLoadingVar = makeVar(false)
export const toastsVar = makeVar([])
export const ingredientGroupsOnlyOwnVar = makeVar(true)
export const isOpenPermissionSimulatorVar = makeVar(false)
export const isActiveToolTipTourVar = makeVar(true)
export const hasClosedCarouselWindowSmallVar = makeVar(false)
export const isOpenCarouselWindowSmallVar = makeVar(true)
export const isOpenCarouselWindowBigVar = makeVar(false)
export const isOpenCarouselWindowLauncherVar = makeVar(false)
export const videoCarouselActiveStepVar = makeVar(0)
export const isOpenInviteModalVar = makeVar(false)

export const resetCache = () => cache.reset()
