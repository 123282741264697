import { withIronSessionSsr } from 'iron-session/next'

const isProduction = process.env.NEXT_PUBLIC_ENV === 'production'

export const options = {
  password: process.env.IRON_SESSION_PASSWORD,
  cookieName: process.env.IRON_SESSION_COOKIE_NAME,
  cookieOptions: isProduction
    ? {
        secure: true,
        domain: '.wellifize.app',
        path: '/'
      }
    : { secure: false }
}

export const createGetServerSideProps = permissionGroup => {
  return withIronSessionSsr(async ({ req }) => {
    const token = req.session.accessToken
    // const profile = req.session.profile

    if (token === undefined) {
      return { redirect: { destination: '/login', permanent: false } }
    }

    return { props: { token } }
  }, options)
}

export const getServerSideProps = withIronSessionSsr(async ({ req }) => {
  try {
    const token = req.session.accessToken
    const coachingPortalAccessToken =
      req.session.coachingPortalAccessToken || null
    // const profile = req.session.profile

    if (token === undefined) {
      return {
        redirect: {
          destination: '/login',
          permanent: false
        }
      }
    }

    return {
      props: {
        token,
        coachingPortalAccessToken
      }
    }
  } catch (error) {
    return { props: { error: 'The cookie is not valid.' } }
  }
}, options)
