import { ApolloProvider } from '@apollo/client'
import { EuiProvider } from '@elastic/eui'
import App from 'next/app'
import { ProfileProvider } from '../context/profile'
import AutoLogout from '@/components/AutoLogout'
import { useApollo } from '../graphql/client'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { options } from '../lib/session'
import { getIronSession } from 'iron-session'
import { GraphQLClient } from 'graphql-request'
import { ME_QUERY } from '../graphql/queries/me'
import '../styles/globals.css'
import '../styles/suggestic/eui_theme_light.min.css'

export default function MyApp({ Component, pageProps, newProfile }) {
  const apolloClient = useApollo(pageProps.initialApolloState, {
    token: pageProps.token
  })
  const router = useRouter()

  const pageView = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    window.analytics.page(params)
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView)
    return () => router.events.off('routeChangeComplete', pageView)
  }, [router])

  return (
    <ApolloProvider client={apolloClient}>
      <ProfileProvider initialState={{ ...pageProps, profile: newProfile }}>
        <EuiProvider colorMode="light">
          <AutoLogout>
            <Component {...pageProps} />
          </AutoLogout>
        </EuiProvider>
      </ProfileProvider>
    </ApolloProvider>
  )
}

MyApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext)
  let accessToken

  const url = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT
  const client = new GraphQLClient(url)
  let profile = { permissions: [], name: '', email: '', roles: [] }
  const getMe = async variables => {
    try {
      const response = await client.request(ME_QUERY, variables)
      return response.me
    } catch (err) {
      if (appContext.ctx.req) {
        const session = await getIronSession(
          appContext.ctx.req,
          appContext.ctx.res,
          options
        )
        session.destroy()
      }

      return
    }
  }

  if (appContext.ctx.req && appContext.ctx.res) {
    const session = await getIronSession(
      appContext.ctx.req,
      appContext.ctx.res,
      options
    )
    accessToken = session.accessToken
    if (accessToken) {
      client.setHeader('Authorization', `Bearer ${accessToken}`)
      profile = await getMe()
    }
  }
  return { ...appProps, newProfile: profile }
}
